var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.page_data)?_c('div',{staticClass:"sectionNavbar"},[_c('div',{staticClass:"custom-breadcrumb d-flex justify-content-between pl-1"},[_c('bread-crumb'),_c('div',{staticClass:"arrows"},[(_vm.currentIndex != 0)?_c('div',{staticClass:"arrow mr-2 cursor-pointer",on:{"click":_vm.prev}},[_c('img',{attrs:{"src":_vm.$store.state.appConfig.isRTL
              ? require('@/assets/images/icons/arrow-n.png')
              : require('@/assets/images/icons/arrow-p.png'),"alt":""}}),_vm._v(" "+_vm._s(_vm.$t("g.previous"))+" ")]):_vm._e(),(
          _vm.listSections &&
          _vm.listSections.length > 0 &&
          _vm.currentIndex < _vm.listSections.length - 1
        )?_c('div',{staticClass:"arrow cursor-pointer",on:{"click":_vm.next}},[_vm._v(" "+_vm._s(_vm.$t("g.next"))+" "),_c('img',{attrs:{"src":_vm.$store.state.appConfig.isRTL
              ? require('@/assets/images/icons/arrow-p.png')
              : require('@/assets/images/icons/arrow-n.png'),"alt":""}})]):_vm._e()])],1),_c('div',{staticClass:"ml-1"},[_c('div',{staticClass:"data"},[_c('img',{attrs:{"src":_vm.Calenderico,"alt":""}}),(_vm.page_data.is_assignment === true)?_c('span',[_c('span',{staticClass:"variant-primary"},[_vm._v(_vm._s(_vm.$t("g.from"))+" "),_c('span',{staticClass:"ml-0"},[_vm._v(_vm._s(_vm.dateHandeling(_vm.page_data.assignment.start_date, true)))])]),_c('span',{staticClass:"variant-primary"},[_vm._v(_vm._s(_vm.$t("g.to"))+" "),_c('span',{staticClass:"ml-0"},[_vm._v(_vm._s(_vm.dateHandeling(_vm.page_data.assignment.due_date, true)))])])]):_c('span',[_c('span',{staticClass:"variant-primary"},[_vm._v(_vm._s(_vm.$t("g.from"))+" "),_c('span',{staticClass:"ml-0"},[_vm._v(_vm._s(_vm.dateHandeling(_vm.page_data.created_at, false)))])])])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }