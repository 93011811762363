<template>
  <validation-observer ref="addlessonForm">
    <b-form>
      <b-modal
        id="bv-modal-AddLiveLesson"
        ref="bv-modal-AddLiveLesson"
        centered
        hide-footer
        :title="
          isEdit
            ? $t('g.add_live_lesson_overlay/titl_edit')
            : $t('g.add_live_lesson_overlay/title')
        "
      >
        <span class="sub-title m-2">
          {{ $t("g.add_lesson_overlay/header") }}</span
        >
        <div class="add-lesson-overlay">
          <b-row>
            <b-col cols="12">
              <b-form-group label-for="name" class="m-2">
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    type="text"
                    v-model="form_data.name"
                    :placeholder="$t('g.name')"
                    name="name"
                    class="input"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label-for="description" class="mx-2">
                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-textarea
                    rows="5"
                    max-rows="10"
                    v-model="form_data.introduction"
                    no-resize
                    :placeholder="$t('g.description')"
                    name="description"
                    class="input text-area"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label-for="start date" class="mx-2">
                <validation-provider
                  #default="{ errors }"
                  name="start date"
                  rules="required"
                >
                  <b-form-datepicker
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    :placeholder="$t('g.start_date')"
                    v-model="form_data.start_date"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <b-col class="d-flex justify-content-end mt-2">
            <b-button
              variant="outline-primary"
              class="mx-1 form-btn-outline"
              @click="$bvModal.hide('bv-modal-AddLiveLesson')"
              >{{ $t("g.cancel") }}</b-button
            >
            <b-button ref="formButton" class="form-btn" @click="validateForm()">
              {{ isEdit ? $t("g.edit") : $t("g.save") }}</b-button
            >
          </b-col>
        </b-row>
        <overlay-component :isLoading="isLoading" />
      </b-modal>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BModal,
  BButton,
  BDropdown,
  BFormFile,
  BFormGroup,
  BFormInput,
  BDropdownItem,
  BFormTextarea,
  BFormDatepicker,
  BVToastPlugin,
} from "bootstrap-vue";
import { required } from "@validations";
import OverlayComponent from "@/components/shared/OverlayComponent/index.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  name: "addLessonOverlay",
  props: ["course_id", "section_id", "id_to_edit"],
  components: {
    BCol,
    BRow,
    BForm,
    BModal,
    BButton,
    BFormFile,
    BDropdown,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BDropdownItem,
    BFormDatepicker,
    OverlayComponent,
    ValidationObserver,
    ValidationProvider,
    BVToastPlugin,
  },
  data() {
    return {
      form_data: {
        name: null,
        introduction: null,
        is_assignment: 0,
        is_required: 1,
        is_visible: 1,
        start_date: null,
      },
      isEdit: false,
      editingId: null,
      isLoading: false,
      localCourseId: null,
      localsectionId: null,
    };
  },
  mounted() {
    this.localCourseId = this.course_id ? this.course_id : null;
    this.localsectionId = this.section_id;
    this.clearFormData();
  },
  watch: {
    section_id(newVal, oldVal) {
      this.localsectionId = newVal;
    },
    selectedFiles(newVal, oldVal) {
      newVal.forEach((e) => this.form_data.resources.push(e));
    },
    id_to_edit(newVal, oldVal) {
      if (newVal != null) {
        this.isEdit = true;
        this.editingId = newVal;
        this.getCurrentSectionData();
      } else {
        this.isEdit = false;
        this.editingId = null;
        this.clearFormData();
      }
    },
  },
  methods: {
    clearFormData() {
      let notAllowedItems = [
        "resources",
        "is_completion_required",
        "color",
        "is_visible",
        "is_locked",
        "is_required",
        "is_assignment",
      ];
      for (let item in this.form_data) {
        if (!notAllowedItems.includes(item)) {
          this.form_data[item] = null;
        }
        if (item == "resources") {
          this.form_data.resources.forEach((e) => {
            this.form_data.resources.splice(0, this.form_data.resources.length);
          });
          this.selectedFiles.length = 0;
        }
      }
    },
    validateForm() {
      this.$refs.addlessonForm.validate().then((sucess) => {
        if (sucess) {
          if (this.isEdit) {
            this.submitEditForm();
          } else {
            this.submitform();
          }
        } else {
          this.$refs.formButton.disabled = false;
        }
      });
    },
    submitform() {
      this.$emit("getSetcionId");
      this.$refs.formButton.disabled = true;
      const formData = new FormData();
      for (let item in this.form_data) {
        formData.append(item, this.form_data[item]);
      }
      formData.append("meet", 1);
      this.$http
        .post(
          `teacher/courses/${this.localCourseId}/lessons/${this.localsectionId}/sections`,
          formData
        )
        .then((response) => {
          this.$emit("resetData");
          this.$refs.formButton.disabled = false;
          this.makeToast(
            "success",
            response.data.message,
            this.$t("g.toast/header_successfully")
          );
          this.clearFormData();
          this.$bvModal.hide("bv-modal-AddLiveLesson");
        })
        .catch((error) => {
          this.$refs.formButton.disabled = false;
          for (const key in error.response?.data?.errors) {
          }
        });
    },
    submitEditForm() {
      this.$emit("getSetcionId");
      this.$refs.formButton.disabled = true;

      const formData = new FormData();
      for (let item in this.form_data) {
        formData.append(item, this.form_data[item]);
      }
      this.$http
        .post(
          `teacher/courses/${this.localCourseId}/lessons/${this.localsectionId}/sections/${this.editingId}?_method=put`,
          formData
        )
        .then((response) => {
          this.$emit("resetData");

          this.$refs.formButton.disabled = false;
          this.makeToast(
            "success",
            response.data.message,
            this.$t("g.toast/header_successfully")
          );
          this.$bvModal.hide("bv-modal-AddLiveLesson");
        })
        .catch((error) => {
          this.$refs.formButton.disabled = false;
          for (const key in error.response?.data?.errors) {
            this.makeToast(
              "danger",
              error.response.data.errors[key][0],
              this.$t("g.send.errorTitle")
            );
          }
        });
    },
    deleteItem(itemName) {
      this.form_data.resources = this.form_data.resources.filter(
        (el) => el.name != itemName
      );
      this.$refs.fileInput.$el.click();
    },
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: true,
      });
    },
    getCurrentSectionData() {
      this.isLoading = true;
      const id = this.editingId;
      if (this.localCourseId) {
        this.$http
          .get(
            `teacher/courses/${this.localCourseId}/lessons/${this.localsectionId}/sections/${id}`
          )
          .then((response) => {
            this.isLoading = false;
            for (let item in this.form_data) {
              this.form_data[item] = response.data.data[item];
              if (
                item == "is_completion_required" ||
                item == "is_visible" ||
                item == "is_locked" ||
                item == "is_required" ||
                item == "is_assignment"
              ) {
                this.form_data[item] = response.data.data[item] ? 1 : 0;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.makeToast("danger", err.message, this.$t("g.send.errorTitle"));
          });
      }
    },
    compareShortDates() {
      if (this.form_data.start_date && this.form_data.end_date) {
        const startDate = new Date(this.form_data.start_date);
        const endDate = new Date(this.form_data.end_date);
        if (startDate > endDate) {
          this.dateError = true;
          return 0;
        } else if (startDate < endDate) {
          this.dateError = false;
          return 1;
        }
      }
    },
  },
};
</script>
<style lang="scss" src="./_addLiveLessonOverlay.scss"></style>
