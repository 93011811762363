<template>
  <validation-observer ref="addlessonForm">
    <b-form>
      <b-modal
        id="bv-modal-AddLesson"
        ref="bv-modal-AddLesson"
        centered
        hide-footer
        :title="
          isEdit
            ? $t('g.add_lesson_overlay/titl_edit')
            : $t('g.add_lesson_overlay/title')
        "
      >
        <span class="sub-title m-2">
          {{ $t("g.add_lesson_overlay/header") }}</span
        >
        <div class="add-lesson-overlay">
          <b-form-group label-for="name" class="m-2">
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-input
                type="text"
                v-model="form_data.name"
                :placeholder="$t('g.name')"
                name="name"
                class="input"
              />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="description" class="mx-2">
            <validation-provider
              #default="{ errors }"
              name="description"
              rules="required"
            >
              <b-form-textarea
                rows="5"
                max-rows="10"
                v-model="form_data.introduction"
                no-resize
                :placeholder="$t('g.description')"
                name="description"
                class="input text-area"
              />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group class="mx-2" label-for="start date">
            <validation-provider
                #default="{ errors }"
                name="start date"
                rules="required"
            >
              <flat-pickr
                  v-model="form_data.start_date"
                  class="form-control py-1"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                  :placeholder="$t('g.start_date')"
              />
              <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-file
            ref="fileInput"
            :hidden="true"
            v-model="selectedFiles"
            accept=".jpg, .png, .gif"
            multiple
            plain
          />
          <b-row
            class="mx-2"
            v-if="
              Array.isArray(form_data.resources) &&
              form_data.resources.length == 0
            "
          >
            <div
              class="import-continier w-100"
              @click="$refs.fileInput.$el.click()"
            >
              <span class="box mt-2">
                <img :src="importImge" alt="image-import" />
                <span>{{
                  $t("g.add_lesson_overlay/form/import_file_text")
                }}</span>
              </span>
              <br />
            </div>
          </b-row>
          <b-row v-else class="mx-2">
            <span>{{ $t("g.Instructions") }}</span>
            <div
              class="resources-preview w-100"
              v-for="item in form_data.resources"
              :key="item.name"
            >
              <img
                class="sm-img-preview"
                :src="pdfIco"
                alt="Instructions-Type"
              />
              <span class="name"> {{ item.name }}</span>
              <div
                tabindex="0"
                role="button"
                @click="deleteItem(item.name)"
                class="float-right"
              >
                <img :src="PenIco" alt="pen-tool" />
              </div>
            </div>
          </b-row>
        </div>
        <b-row>
          <b-col class="d-flex justify-content-end mt-2">
            <b-button
              variant="outline-primary"
              class="mx-1 form-btn-outline"
              @click="$bvModal.hide('bv-modal-AddLesson')"
              >{{ $t("g.cancel") }}</b-button
            >
            <b-button ref="formButton" class="form-btn" @click="validateForm()">
              {{ isEdit ? $t("g.edit") : $t("g.save") }}</b-button
            >
          </b-col>
        </b-row>
        <overlay-component :isLoading="isLoading" />
      </b-modal>
    </b-form>
  </validation-observer>
</template>
<script>
import {
  BRow,
  BCol,
  BForm,
  BModal,
  BButton,
  BDropdown,
  BFormFile,
  BFormGroup,
  BFormInput,
  BDropdownItem,
  BFormTextarea,
  BFormDatepicker,
  BVToastPlugin,
} from "bootstrap-vue";
import { required } from "@validations";
import OverlayComponent from "@/components/shared/OverlayComponent/index.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";

export default {
  name: "addLessonOverlay",
  props: ["course_id", "section_id", "id_to_edit"],
  components: {
    BCol,
    BRow,
    BForm,
    BModal,
    BButton,
    BFormFile,
    BDropdown,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BDropdownItem,
    BFormDatepicker,
    OverlayComponent,
    ValidationObserver,
    ValidationProvider,
    BVToastPlugin,
    flatPickr
  },
  data() {
    return {
      dateOption: {
        month: "short",
        weekday: "short",
      },
      form_data: {
        name: null,
        introduction: null,
        start_date: null,
        end_date: null,
        resources: [],
        is_completion_required: 1,
        is_visible: 1,
        is_locked: 0,
        color: 1,
        is_required: 1,
        is_assignment: 0,
      },
      dateError: null,
      isEdit: false,
      editingId: null,
      selectedFiles: [],
      isLoading: false,
      localCourseId: null,
      localsectionId: null,
      importImge: require("@/assets/images/icons/symbols_upload.svg"),
      pdfIco: require("@/assets/images/icons/pdf.png"),
      fileIco: require("@/assets/images/icons/doc.png"),
      PenIco: require("@/assets/images/icons/pencil.svg"),
    };
  },
  mounted() {
    this.localCourseId = this.course_id ? this.course_id : null;
    this.localsectionId = this.section_id;
    this.clearFormData();
  },
  watch: {
    section_id(newVal, oldVal) {
      this.localsectionId = newVal;
    },
    selectedFiles(newVal, oldVal) {
      newVal.forEach((e) => this.form_data.resources.push(e));
    },
    id_to_edit(newVal, oldVal) {
      if (newVal != null) {
        this.isEdit = true;
        this.editingId = newVal;
        this.getCurrentSectionData();
      } else {
        this.isEdit = false;
        this.editingId = null;
        this.clearFormData();
      }
    },
  },
  methods: {
    clearFormData() {
      let notAllowedItems = [
        "resources",
        "is_completion_required",
        "color",
        "is_visible",
        "is_locked",
        "is_required",
        "is_assignment",
      ];
      for (let item in this.form_data) {
        if (!notAllowedItems.includes(item)) {
          this.form_data[item] = null;
        }
        if (item == "resources") {
          this.form_data.resources.forEach((e) => {
            this.form_data.resources.splice(0, this.form_data.resources.length);
          });
          this.selectedFiles.length = 0;
        }
      }
    },
    validateForm() {
      this.$refs.addlessonForm.validate().then((sucess) => {
        if (sucess) {
          if (this.isEdit) {
            this.submitEditForm();
          } else {
            this.submitform();
          }
        } else {
          this.$refs.formButton.disabled = false;
        }
      });
    },
    submitform() {
      this.$emit("getSetcionId");
      this.$refs.formButton.disabled = true;

      const formData = new FormData();
      for (let item in this.form_data) {
        if (this.form_data[item] !== null){
        formData.append(item, this.form_data[item]);
        }
        if (item == "resources") {
          for (let item in this.form_data.resources) {
            formData.append(
              `resources[${item}]`,
              this.form_data.resources[item]
            );
          }
        }
      }
      this.$http
        .post(
          `teacher/courses/${this.localCourseId}/lessons/${this.localsectionId}/sections`,
          formData
        )
        .then((response) => {
          this.$emit("resetData");
          this.$refs.formButton.disabled = false;
          this.makeToast(
            "success",
            response.data.message,
            this.$t("g.toast/header_successfully")
          );
          this.clearFormData();
          this.$bvModal.hide("bv-modal-AddLesson");
        })
        .catch((error) => {
          this.$refs.formButton.disabled = false;
          for (const key in error.response?.data?.errors) {
          }
        });
    },
    submitEditForm() {
      const dateState = this.compareShortDates();
      if (!dateState) return false;
      this.$emit("getSetcionId");
      this.$refs.formButton.disabled = true;

      const formData = new FormData();
      for (let item in this.form_data) {
        if (this.form_data[item] !== null){
          formData.append(item, this.form_data[item]);
        }
        if (item == "resources") {
          for (let item in this.form_data.resources) {
            formData.append(
              `resources[${item}]`,
              this.form_data.resources[item]
            );
          }
        }
      }
      this.$http
        .post(
          `teacher/courses/${this.localCourseId}/lessons/${this.localsectionId}/sections/${this.editingId}?_method=put`,
          formData
        )
        .then((response) => {
          this.$emit("resetData");

          this.$refs.formButton.disabled = false;
          this.makeToast(
            "success",
            response.data.message,
            this.$t("g.toast/header_successfully")
          );
          this.$bvModal.hide("bv-modal-AddLesson");
        })
        .catch((error) => {
          this.$refs.formButton.disabled = false;
          for (const key in error.response?.data?.errors) {
            this.makeToast(
              "danger",
              error.response.data.errors[key][0],
              this.$t("g.send.errorTitle")
            );
          }
        });
    },
    deleteItem(itemName) {
      this.form_data.resources = this.form_data.resources.filter(
        (el) => el.name != itemName
      );
      this.$refs.fileInput.$el.click();
    },
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: true,
      });
    },

    getCurrentSectionData() {
      this.isLoading = true;
      const id = this.editingId;
      if (this.localCourseId) {
        this.$http
          .get(
            `teacher/courses/${this.localCourseId}/lessons/${this.localsectionId}/sections/${id}`
          )
          .then((response) => {
            this.isLoading = false;
            for (let item in this.form_data) {
              this.form_data[item] = response.data.data[item];
              if (
                item == "is_completion_required" ||
                item == "is_visible" ||
                item == "is_locked" ||
                item == "is_required" ||
                item == "is_assignment"
              ) {
                this.form_data[item] = response.data.data[item] ? 1 : 0;
              }
            }
            this.hanelRecieviedResources();
          })
          .catch((err) => {
            this.makeToast("danger", err.message, this.$t("g.send.errorTitle"));
          });
      }
    },
    compareShortDates() {
      if (this.form_data.start_date && this.form_data.end_date) {
        const startDate = new Date(this.form_data.start_date);
        const endDate = new Date(this.form_data.end_date);
        if (startDate > endDate) {
          this.dateError = true;
          return 0;
        } else if (startDate < endDate) {
          this.dateError = false;
          return 1;
        }
      }
    },
    hanelRecieviedResources() {
      this.form_data.resources.forEach((e) => {
        e.name = e.description;
      });
    },
  },
};
</script>
<style lang="scss" src="./_addLessonOverlay.scss"></style>
