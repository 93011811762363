<template>
  <div class="sectionAssignments" v-if="page_data && page_data.length != 0">
    <b-row class="define-component">
      <span class="px-1 text mt-3">{{ $t("g.instructions") }}</span>
    </b-row>
    <div class="holder">
      <div v-for="(file, index) in page_data.resources" :key="file.id">
        <div class="d-flex justify-content-between align-items-center p-2">
          <div class="title">
            <img
              :src="
                file.type.includes('image')
                  ? require('@/assets/images/icons/photo.png')
                  : require('@/assets/images/icons/Group.png')
              "
              class="image mr-1"
            />
            <span>{{ file.description.split(".")[0] }}</span>
          </div>
          <a :href="file.path" hidden target="_blank" :id="file.id"></a>
          <div @click="dwonloadFile(file.id)" class="file">
            <span>{{ $t("g.view") }}</span>
          </div>
        </div>
        <hr v-if="index != page_data.resources.length - 1" />
      </div>
    </div>
    <b-row v-if="started" class="d-flex justify-content-center">
      <b-col class="d-flex justify-content-center">
        <b-button class="p-1" @click="toAnswers" variant="primary">{{
          $t("g.section_assigment/view_answers")
        }}</b-button></b-col
      >
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";
export default {
  name: "SectionAssignments",
  props: ["page_data", "assignment_id", "course_id"],
  components: {
    BRow,
    BButton,
    BCol,
  },
  data() {
    return {
      started: true,
    };
  },
  watch: {
    "page_data.assignment.start_date": {
      deep: true,
      handler(newVal, oldVal) {
        const now = new Date();
        const start_date = new Date(newVal);
        if (newVal < start_date) {
          this.started = true;
        }
      },
    },
  },
  created() {
    console.log(this.course_id);
  },
  methods: {
    dwonloadFile(id) {
      document.getElementById(id).click();
    },
    toAnswers() {
      // this.$store.commit("BreadCrumb/Set_BreadCrumb", {
      //   index: 2,
      //   value: {
      //     text: this.$store.state.BreadCrumb?.list[2].text,
      //     active: true,
      //   },
      // });
      // this.$store.commit("BreadCrumb/AddBreadcrumb", {
      //   text: "Answers",
      //   active: false,
      // });
      this.$router.push({
        name: "answers",
        params: { id: this.assignment_id },
        query: { course_id: this.course_id },
      });
    },
  },
};
</script>

<style src="./_sectionAssignments.scss" lang="scss" scoped></style>
